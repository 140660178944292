var api = require("!../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoLinkTag.js");
            var content = require("!!../../../../../node_modules/file-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/esbuild-loader/dist/index.cjs??ruleSet[1].rules[3].use[2]!./iconfont.css");

            content = content.__esModule ? content.default : content;

var options = {"injectType":"linkTag"};

options.insert = "head";

var update = api(content, options);



