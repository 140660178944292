import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import startOfDay from 'date-fns/startOfDay';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import format from 'date-fns/format';

const today = startOfDay(addDays(new Date(), -2))

const initialState = {
  categories: [],
  dateRange: {
    start_date: format(startOfDay(subDays(today, 6)), 'yyyy-MM-dd'),
    end_date:  format(today, 'yyyy-MM-dd')
  },
  country: 'us',
  searchingValue: '',
  currency: 'USD',
  language: '',
  categoryList: [],
  shopRankingTotal: 0,
  productRankingTotal: 0,
  userCurrency: '$',
  levelWiseCategory: {
    level1: [],
    level2: [],
    level3: []
  }
}


export const handleLogout = createAsyncThunk('header/logout', async (user_id) => {
  const header = { 'refresh-token': localStorage.getItem('refresh_tokens') }
  const finalObj = {
    userId: user_id
  }
  const response = await axios.post(`${apiEndpoint}/log-out`, finalObj, { headers: header })
  return response
})

export const commonSlice = createSlice({
  name: 'commonData',
  initialState,
  reducers: {
    setCategoriesValue: (state, action) => {
      state.categories = action.payload
    },
    setLevelWiseCategory: (state, action) => {
      state.levelWiseCategory = action.payload
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    setCountryCode: (state, action) => {
      state.country = action.payload
    },
    setSearchingValue: (state, action) => {
      state.searchingValue = action.payload
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload
    },
    resetUserDetail: (state) => {
      state.user_name = ''
    },
    seCurrency: (state, action) => {
      state.currency = action.payload
    },
    seUserShowCurrency: (state, action) => {
      state.userCurrency = action.payload
    },
    setLanguage: (state, action) => {
      state.language = action.payload
    },
    setShopRankingTotal: (state, action) => {
      state.shopRankingTotal = action.payload
    },
    setProductRankingTotal: (state, action) => {
      state.productRankingTotal = action.payload
    },
    cleanState: (state) => {
      state.dateRange = initialState.dateRange
      state.categories = initialState.categories
      state.searchingValue = initialState.searchingValue
      state.levelWiseCategory = initialState.levelWiseCategory
    },
    extraReducers: builder => {
      builder
        .addCase(handleLogout.fulfilled, (state) => {
          state.logout = true
        })
    }

  }
})

// Action creators are generated for each case reducer function
export const { setCategoriesValue,  setDateRange, setCountryCode, setSearchingValue, resetUserDetail, setUserInfo, setCategoryList, seCurrency, setLanguage, setShopRankingTotal, setProductRankingTotal, cleanState, seUserShowCurrency, setLevelWiseCategory } = commonSlice.actions

export default commonSlice.reducer