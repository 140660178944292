let apiEndpoint
let commonApi
const hostname = window.location.hostname
const isLive = window.location.hostname !== 'localhost'

if (hostname === 'dev-tiktok.identixweb.com') {
    apiEndpoint = 'https://dev-tiktok-api.identixweb.com/admin_api'
    commonApi = 'https://dev-tiktok.identixweb.com/'
} else if  (hostname === 'test-tiktok.identixweb.com') {
    apiEndpoint = 'https://test-tiktok-api.identixweb.com/admin_api'
    commonApi = 'https://test-tiktok.identixweb.com/'
} else if (hostname === 'devrbz.identixweb.com') {
    apiEndpoint = 'https://devrbz-api.identixweb.com'
    commonApi = 'https://devrbz.identixweb.com/'
} else if (hostname === 'devodd.identixweb.com') {
    apiEndpoint = 'https://devodd.identixweb.com/dev_service/admin_api'
    commonApi = 'https://devodd.identixweb.com/'
} else if (hostname === 'stage-tiktok.identixweb.com') {
    apiEndpoint = 'https://stage-tiktok-api.identixweb.com/admin_api'
    commonApi = 'https://stage-tiktok.identixweb.com/'
} else if (hostname === 'localhost') {
    apiEndpoint = 'http://localhost:4000/admin_api'
    commonApi = 'http://localhost:8070/'
} else if ('create10.io') {
    apiEndpoint = 'https://create10.io/api'
    commonApi = 'https://create10.io'
}

module.exports = {
    apiEndpoint,
    commonApi,
    isLive
}
