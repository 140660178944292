// ** Logo
//import SmallLogo from '../../../common_components/SmallLogo'
import React from 'react'
import LogoGif from '../../../assets/images/logo/Gif.gif'
const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader  w-100'>
      <img src={LogoGif} />
    </div>
  )
}

export default SpinnerComponent
