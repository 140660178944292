const CommonRouter = {
    dashboard: '/dashboard',
    login: '/login',
    sign_up: '/sign-up',
    forgot_pwd: '/forgot-password',
    setting: '/setting',
    ai_video_creator: '/ai-video-creator',
    ai_video_ads: '/ai-video-creator/ai-video-ads',
    upload_manually: '/ai-video-creator/upload-manually',
    video_setting: '/ai-video-creator/video-setting',
    ai_avatar: '/ai-video-creator/ai-avatar',
    ai_avatar_preview: '/ai-video-creator/ai-avatar-preview',
    ai_video_preview: '/ai-video-creator/ai-video-preview',
    own_avatar: '/ai-video-creator/own-avatar',
    avatar_step: '/ai-video-creator/avatar-step',
    avatar_shorts: '/ai-video-creator/avatar-shorts',
    ai_shorts: '/ai-video-creator/ai-shorts',
    ai_editing: '/ai-video-creator/ai-editing',
    ai_editing_preview_video: '/ai-video-creator/editing-preview-video',
    ai_editing_style: '/ai-video-creator/editing-style',
    batch_mode: '/ai-video-creator/batch-mode',
    text_speech: '/ai-video-creator/text-speech',
    fav_avatar: '/ai-video-creator/fav-avatar',
    choose_script: '/ai-video-creator/choose-create-script',
    selected_videos: '/ai-video-creator/selected-videos',
    my_videos: '/my-videos',
    generate_new_video: '/generate-new-videos',
}
export default CommonRouter
