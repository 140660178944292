import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiCall, GetApiCall } from '../helper/axios';


const initialState = {
  aiAvtarData: {
    loader: false,
    data: {}
  },
  productId: '',
  videoSettings: {}
};

export const singleprojectget = createAsyncThunk('get/singleprojectget', async ({ id }) => {
  const userData = localStorage.getItem('userData');
  return await GetApiCall("GET", `/singleprojectget?id=${id}`, { authentication: JSON.parse(userData)?.accesstoken }, true);
});

export const productManually = createAsyncThunk('post/productManually', async ({ data }) => {
  const userData = localStorage.getItem('userData');
  return await ApiCall('POST', '/productManually', data, { authentication: JSON.parse(userData)?.accesstoken });
});

export const productUpdate = createAsyncThunk('update/productUpdate', async ({ data }) => {
  const userData = localStorage.getItem('userData');
  return await ApiCall('POST', '/productUpdate', data, { authentication: JSON.parse(userData)?.accesstoken });
});

export const aiAvtarData = createSlice({
  name: 'aiAvtarData',
  initialState,
  reducers: {
    setAiAvtarData: (state, action) => {
      state.aiAvtarData = action.payload;
    },
    setProductID: (state, action) => {
      state.productId = action.payload;
    },
    setVideoSettings: (state, { payload }) => {
      state.aiAvtarData.data = { ...state.aiAvtarData.data, ...payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(singleprojectget.pending, (state) => {
        state.aiAvtarData.loader = true;
      })
      .addCase(singleprojectget.fulfilled, (state, { payload }) => {
        if (payload.status === 200) {
          state.aiAvtarData.loader = false;
          state.aiAvtarData.data = payload?.data?.data;
        } else {
          // window.location.href = '/';
        }
      })
      .addCase(singleprojectget.rejected, (state) => {
        window.location.href = '/';
        state.aiAvtarData.loader = true;
        state.aiAvtarData.data = {};
      })
      .addCase(productManually.fulfilled, (state, { payload }) => {
        state.aiAvtarData.loader = false;
        state.aiAvtarData.data = payload?.data?.data;
      })
      .addCase(productUpdate.fulfilled, (state, { payload }) => {
        console.log(payload)
        state.aiAvtarData.loader = false;
        // state.aiAvtarData.data = payload?.data?.data;
      })
      ;
  }
});

// Action creators are generated for each case reducer function
export const { setAiAvtarData, setProductID, setVideoSettings } = aiAvtarData.actions;

export default aiAvtarData.reducer;