// ** Reducers Imports
import layout from './layout'
import auth from './authentication'
import commonSlice from './commonSlice'
import userData from './userData'
import aiAvtarData from './aiAvtarData'
import aiEditingData  from './aiEditingData'
import aiAvatarDetail from './aiAvatarDetail'

const rootReducer = {
  auth,
  layout,
  commonSlice,
  userData,
  aiAvtarData,
  aiEditingData,
  aiAvatarDetail
}

export default rootReducer
