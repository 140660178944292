// ** Reducers Imports
import layout from './layout'
import auth from './authentication'
import commonSlice from './commonSlice'
import userData from './userData'

const rootReducer = {
  auth,
  layout,
  commonSlice,
  userData
}

export default rootReducer
