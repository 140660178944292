import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiCall, GetApiCall } from '../helper/axios';


const initialState = {
    aiAvatarDetail: {},
    aiShortDetail: {}
};

export const aiAvatarDetail = createSlice({
    name: 'aiAvatarDetail',
    initialState,
    reducers: {
        setAiAvatarDetail: (state, action) => {
            state.aiAvatarDetail = action.payload;
        },
        setAiShortDetail: (state, action) => {
            state.aiShortDetail = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setAiAvatarDetail, setAiShortDetail } = aiAvatarDetail.actions;

export default aiAvatarDetail.reducer;


