import axios from 'axios'
import { handlePageRefresh } from './commonFunction'
import CryptoJS from "crypto-js"
import CommonRouter from './commonRoute'
const { apiEndpoint } = require('./commonApi')
let axiosInstances = {};

const createInstance = (timeoutVal) => {
  const controller = new AbortController();
  const customAxiosInstance = {
    controller,
    instance: axios.create({
      headers: {

      },
      signal: controller.signal,
      timeout: timeoutVal
    })
  }
  return customAxiosInstance;
}

const getInstance = (pathname, timeout) => {
  if (axiosInstances[pathname] && !axiosInstances[pathname].controller.signal.aborted) {
    return axiosInstances[pathname].instance;
  } else {
    const customAxiosInstance = createInstance(timeout);
    axiosInstances[pathname] = customAxiosInstance;
    return axiosInstances[pathname].instance;
  }
}

const service = axios.create({
  headers: {

  }
})
const handleSuccess = (response) => {
  return response
}

export const cancelPreviousPageRequests = (previosPath) => {
  axiosInstances[previosPath]?.controller.abort();
}

const handleError = (error) => {
  return Promise.reject(error)
}
service.interceptors.response.use(handleSuccess, handleError)

/**
 * IW0030
 * When someone changes in local storage detail at that time that person account will logout for security purpose
 */
const renewToken = () => {
  if (!window.location.pathname.includes('/s/') || !window.location.pathname.includes('/p/')) {
    window.removeEventListener("beforeunload", handlePageRefresh)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refresh_tokens')
    localStorage.removeItem('userData')
    window.location.href = CommonRouter.login
  }
}
export const ApiCall = async (method, path, payload, header, timeout = 120000) => {
  try {
      const instance = getInstance(window.location.pathname, timeout);
      const responce = await instance({
        method,
        url: apiEndpoint + path,
        responseType: 'json',
        data: payload,
        timeout,
        headers: {
          "Content-Type": "application/json",
          ...header
        }
      })
     /*  const responce = await service.request({
        method,
        url: apiEndpoint + path,
        responseType: 'json',
        data: payload,
        timeout,
        headers: {
          "Content-Type": "application/json",
          ...header
        }
      }) */
      return responce
    } catch (error) {
      if (error?.response?.status === 401 && error?.response?.data?.status === 'UNAUTHORIZED') {
        renewToken();
      }
      if (error.message === 'Network Error') {
        console.log(`${error}, Server is not responding, please try again after some time`)
      }
      if (error?.response?.data?.statusCode === 401 && header && !header['access-token']) {
        if (error.response.data.access_expire) {
          renewToken()
        } else if (error.response.data.refresh_expire) {
          return error.response
        }
      } else {
        return error.response
      }
    }
}
/**
 * IW0030
 * here flag is true when api call occur and user is not login
 */
export const GetApiCall = async (method, path, header, flag = false) => {
  if (!header['access-token'] && !flag) {
    renewToken()
  } else {
    try {
      const responce = await service.request({
        method,
        url: apiEndpoint + path,
        responseType: 'json',
        headers: header,
        timeout: 20000
      })
      return responce
    } catch (error) {
      console.log("error---->", error);
      if (error?.response?.status === 401 && error?.response?.data?.status === 'UNAUTHORIZED') {
        renewToken();
      }
      if (error.message === 'Network Error') {
        console.log(`${error}, Server is not responding, please try again after some time`)
      }
      if (error?.response?.data?.statusCode === 401) {
        if (error.response.data.access_expire) {
          renewToken()
        } else {
          return error.response
        }
      } else {
        return error.response
      }
    }
  }
}

// export const to_encrypt = (normalText) => {
//   // Encrypt
//   // REACT_APP_DEV_CHAT_KEY
//   // REACT_APP_LIVE_CHAT_KEY
//   const key = process.env.REACT_APP_CHAT_KEY
//   const encryptedText = CryptoJS.AES.encrypt(JSON.stringify(normalText), key).toString()
//   return encryptedText
// }

// export const to_decrypt = (encryptedText) => {
//   // Decrypt
//   const key = process.env.REACT_APP_CHAT_KEY
//   const bytes = CryptoJS.AES.decrypt(encryptedText, key)
//   const decryptedText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
//   return decryptedText
// }
export function __(key, t) {
  return t(key);
}

export async function updateSettings(data) {
  const userData = localStorage.getItem('userData');
  const res = await ApiCall('POST', '/updateUserData', data, { authentication: JSON.parse(userData)?.accesstoken });
  return res;
}