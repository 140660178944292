import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    aiEditingData: {},
    aiEditingStyleData: {}
}

export const aiEditingData = createSlice({
  name: 'aiEditingData',
  initialState,
  reducers: {
    setAiEditingData: (state, action) => {
      sessionStorage.setItem('tiktok_aiEditingData', JSON.stringify(action.payload))
      state.aiEditingData = action.payload
    },
    setAiEditingStyleData: (state, action) => {
      sessionStorage.setItem('tiktok_aiEditingStyleData', JSON.stringify(action.payload))
      state.aiEditingStyleData = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAiEditingData,  setAiEditingStyleData} = aiEditingData.actions

export default aiEditingData.reducer